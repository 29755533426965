import axios from 'axios'

const GITHUB_URL = 'https://yournextemployee-6538d-default-rtdb.firebaseio.com/companies.json'

const github = axios.create({
	baseURL: GITHUB_URL,
})

var allData;

export const searchUsers = async (text) => {

	if (!allData) {
		console.log("no data, query");
		const response = await github.get("")

		//console.log(response);
		allData = response.data
	} else {
		console.log("have data, no query");
	}

	let retVal = allData

	if (text !== "") {
		console.log("filter data:", text);
		retVal = retVal.filter(element => element.name.toLowerCase().includes(text.toLowerCase()));
	}

	console.log("retval", retVal);
	return retVal
}

export const getUserAndRepos = async (login) => {
	const [user, repos] = await Promise.all([
		github.get(`/users/${login}`),
		github.get(`/users/${login}/repos`),
	])

	return { user: user.data, repos: repos.data }
}
