import React from "react"

function Footer() {
    const footerYear = new Date().getFullYear()
  
    return (
      <footer className='footer p-10 bg-gray-700 text-secondary-content footer-center'>
        <div>
          <p>Copyright &copy; {footerYear} All rights reserved</p>
          <p>info@yournextemployee.io</p>
        </div>
      </footer>
    )
  }
  
  export default Footer
