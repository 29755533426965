import React from 'react'
import { BsPersonPlusFill } from 'react-icons/bs'

function About() {
  return (
    <>
      <h1>
        <BsPersonPlusFill className='inline pr-2 text-6xl lg:text-8xl' />
      </h1>
      <h1 className='text-4xl mb-4 lg:text-6xl'>YourNextEmployee
      <div class="badge badge-secondary text-xs align-top">BETA</div>
      </h1>
      <p className='mb-4 text-2xl font-light'>
        A collection of publicly available layoff lists to help you find your next talent who is immediately available!
      </p>
      <p className='mb-4 text-xl font-light'>
       Any questions? Contact us on <b><u>info@yournextemployee.io</u></b>
      </p>
      <p className='mb-4 text-xl font-light'>
       Bugs? Misinformation? Contact us on <b><u>bug@yournextemployee.io</u></b>
      </p>
      <p className='text-lg text-gray-400'>
        Version: <span> 1.0.0</span>
      </p>
    </>
  )
}

export default About
