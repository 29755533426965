import React from 'react'
import { useContext } from 'react'
import UserItem from './UserItem'
import GithubContext from '../../context/github/GithubContext'
import Spinner from '../layout/Spinner'

function UserResults() {
const {users, loading} = useContext(GithubContext)

	if (!loading) {
		return (
			<div className='grid gap-4 lg:grid-cols-3 md:grid-cols-2'>
				{users.map((user) => (
					<UserItem key={user.id} user={user}/>
				))}
			</div>
		)
	} else {
		return <Spinner />
	}
}

export default UserResults
