import React, { useState, useContext } from 'react'
import GithubContext from '../../context/github/GithubContext'
import AlertContext from '../../context/alert/AlertContext'
import {searchUsers} from '../../context/github/GithubActions'
import { ACTIONS } from '../../context/github/GithubReducer';
import { useEffect } from 'react';
import Alert from '../layout/Alert';

var loaded = false
var loading = false
var lastSearchText = ""

function UserSearch() {
	const [text, setText] = useState('');
	const { users, dispatch } = useContext(GithubContext);
	const { setAlert } = useContext(AlertContext);


	const loadData = async (text) => {
		if (!loaded && !loading) {
			lastSearchText = text
			// Update the document title using the browser API
			loading = true
			dispatch({type: ACTIONS.SET_LOADING});

			searchUsers(text).then((data)=>{
				loaded = true
				dispatch({type: ACTIONS.GET_USERS, payload: data});
			})
		}
	}

	const  handleSubmit = async (e) => {
		e.preventDefault();
		loaded = false
		loading = false

		if (text === '') {
			setAlert('Please enter something', 'error');
		} else {
			loadData(text)
		}
	}

	useEffect(() => {
		loadData("");
	});

	return (
		<>
			<div>
				<div className='alert alert-success shadow-lg'>
					<p>
						<span className='inline'><b>2022 is the year of mass layoffs, </b>mainly in the tech industry.
						<br/>
						We collected <b>publicly available layoff lists</b> to <b>help the affected people be more visible</b> and the <b>recruiters quickly find the next candidate</b>.</span>
					</p>
				</div>
				<br/>
				<div className='alert shadow-lg'>
					<span className="text-sm"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="stroke-current flex-shrink-0 w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
		<b>Don't forget: being part of a mass layoff is <u>not</u> the fault of the employee!</b></span>
				</div>
			</div>
			<div className='grid grid-cols-1 mb-8 gap-8'>
				<div>
					<Alert />
					<form onSubmit={handleSubmit}>
						<div className='form-control'>
							<div className='relative'>
								<input
									type='text'
									className='w-full pr-40 bg-gray-200 input input-lg text-black'
									placeholder='Start filtering on company name...'
									value={text}
									onChange={(e) => {
										setText(e.target.value)
									}}
								/>
								<button
									type='submit'
									className='absolute top-0 right-0 rounded-l-none w-36 btn btn-lg'
								>
									Filter
								</button>
							</div>
						</div>
					</form>
				</div>
				{users.length > 0 && lastSearchText !== "" && (
					<div>
						<button
							className='btn btn-ghost btn-large'
							onClick={() => {
								loaded = false
								loading = false
								loadData("")
								setText("")
							}}
						>
							Clear
						</button>
					</div>
				)}
			</div>
		</>
	)
}

export default UserSearch;
