import React from 'react'
import { BsPersonPlusFill } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

function Navbar({ title }) {
	return (
		<nav className='navbar shadow-lg bg-neutral text-neutral-content'>
			<div className='container mx-auto'>
				<div className='flex-none px-2 mx-2'>
					<Link to={'/'}>
						<BsPersonPlusFill className='inline pr-2 text-3xl hidden md:inline' />
					</Link>
					<Link to='/' className='text-md lg:text-lg font-bold align-middle'>
						{title}
						<div class="badge badge-secondary text-xs align-top inline text-[8px]">BETA</div>
					</Link>
				</div>

				<div className='flex-1 px-2 mx-2'>
					<div className='flex justify-end'>
						<Link to='/' className='btn btn-ghost btn-sm rounded-btn'>
							Home
						</Link>
						<Link to='/about' className='btn btn-ghost btn-sm rounded-btn'>
							About
						</Link>
					</div>
				</div>
			</div>
		</nav>
	)
}

Navbar.defaultProps = {
	title: 'YourNextEmployee',
}

Navbar.propTypes = {
	title: PropTypes.string,
}

export default Navbar
