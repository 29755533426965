import React from 'react'
import PropTypes from 'prop-types'

const getInitials = (fullName) => {
	const allNames = fullName.trim().split(' ');
	const initials = allNames.reduce((acc, curr, index) => {
	  if(index === 0 || index === allNames.length - 1){
		acc = `${acc}${curr.charAt(0).toUpperCase()}`;
	  }
	  return acc;
	}, '');
	return initials;
  }

function UserItem({ user: { date, list, logo, name, number, percentage, source } }) {
	const imagePlace = logo ?
		<div className="avatar self-center">
			<div className="h-24 my-4 rounded-xl ring ring-offset-0">
			<figure className='grid place-items-center min-h-full'><img src={logo} alt="Shoes" /></figure>
			</div>
		</div> 
		: 
		<div className="avatar placeholder self-center">
			<div className="bg-neutral-focus text-neutral-content rounded-xl w-24 my-4">
				<span className="text-xl">{getInitials(name)}</span>
			</div>
		</div> 

		const sourceLink = source && source.startsWith("http") ?
			<a href={source} target="_blank" rel="noreferrer"><button className="btn btn-wide btn-sm btn-outline btn-primary ">Source link</button></a>
		:
			<button className="btn btn-wide btn-sm btn-disabled ">Internal source</button>

	return (
		<div className="card w-96 bg-base-100 shadow-xl">
		{imagePlace}
		<div className="card-body">
		  <h2 className="card-title">
		  {name}
		  {percentage &&  <div className="badge badge-secondary">{Math.round(percentage*100) + "%"}</div>}
		  </h2>
		  {number && <p>Laid-off: {number} people</p>}
		  {date && <p>Date: {new Date(date).toISOString().slice(0, 10)}</p>}
		  <div className="card-actions justify-center">
		  <a href={list} target="_blank" rel="noreferrer"><button className="btn btn-wide btn-sm btn-primary">Employee list</button></a>
		  {source && sourceLink }
		  </div>
		</div>
	  </div>
	)
}

UserItem.propTypes = {
	user: PropTypes.object.isRequired,
}

export default UserItem
