import React from 'react'
import UserResults from '../users/UserResults'
import UserSearch from '../users/UserSearch'
import Alert from '../layout/Alert';


function Home() {
    return (
      <>
      		<Alert />
          <UserSearch/>
          <UserResults/>
          <div className="toast">
            <div className="alert alert-info tooltip tooltip-info" data-tip="Hiring Hints is a frequent newsletter with tips on where you could find your next talent!">
              <div>
                <span><svg xmlns="http://www.w3.org/2000/svg" class="stroke-current flex-shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" /></svg></span>
                <span>Late in the game? <a href="https://yournextemployee.gumroad.com/l/hiringhints"><b><u>Subscribe to Hiring Hints</u></b></a> to not miss any layoff news!</span>
              </div>
            </div>
          </div>
      </>
    )
}

export default Home